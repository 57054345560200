.hero-button {
  border: 1px solid #0090d2;
  flex-flow: column;
  align-items: center;
  width: 25em;
  padding: 3%;
  transition: background-color .2s;
  display: flex;
}

.hero-button:hover {
  cursor: pointer;
  background-color: #0090d2;
}

.hero-button:hover .hero-icon {
  color: #fff;
}

.hero-button .hero-icon {
  color: #3eb0a7;
  margin-bottom: 10%;
  font-size: 3em;
  transition: color .2s;
}

@media screen and (width <= 768px) {
  .hero-button .hero-icon {
    margin-top: 5%;
  }
}

.hero-text {
  color: #fff;
  font-size: 1.7em;
}

/*# sourceMappingURL=index.deef836f.css.map */
