$blue: #0090d2;
$green: #3eb0a7;
$red: #CC3366;
$orange:#EF5121;
$grey:#54595f;
$primary: $blue;
$danger: $red;
$info: $orange;
$success:$green;
$link:$blue;

$dark:hsl(0, 0%, 21%);
$text: hsl(0, 0%, 4%);