@use "../../styles/colors.scss";
@import "../../../node_modules/bulma/sass/utilities/mixins.sass";


.hero-button{
    display: flex;
    flex-flow: column;
    align-items: center;
    border: 1px solid colors.$blue;
    padding: 3%;
    width: 25em;
    transition: background-color .2s;
     &:hover{
        background-color: colors.$blue;
        cursor:pointer;

        .hero-icon{
            color:#fff;
        }
    }
    // @include mobile {
    //     margin:5%;
    // }
        
    
    //margin:`${deviceType.isMobile() ? "5%" : ""
    
// @media (max-width: 768px) {
//     .hero-button{
//         margin:5%;
//     }
// }
    .hero-icon{
        font-size: 3em;
        color:colors.$green;
        margin-bottom: 10%;
        transition: color .2s;
        @include mobile{
            margin-top:5%;
        }
    }    
   
    
}

.hero-text{
    color: #fff;
    font-size: 1.7em;
}
